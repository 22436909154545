<template>
    <v-overlay>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'team-active-sprint',
    created() {
        let sprint = this.team.sprints.find((s) => s.status === 'active');
        if (!sprint) {
            sprint = this.team.sprints[this.team.sprints.length - 1];
        }
        this.$router.push({
            name: 'team-sprint',
            params: { sprint_id: sprint.id },
        });
    },
    computed: {
        ...mapGetters(['team']),
    },
};
</script>
